new Swiper('.swiper', {
    slidesPerView: 3,
    spaceBetween: 24,
    speed: 400,
    rewind: true,
    allowTouchMove: false,
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
    breakpoints: {
        200: {
            slidesPerView: 1,
            spaceBetween: 8,
            allowTouchMove: true,
        },
        590: {
            slidesPerView: 3,
            spaceBetween: 24,
            allowTouchMove: false,
        }
    }
});
